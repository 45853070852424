import { useEffect } from 'react';

const useFixedLayout = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';

    return () => {
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('position');
    };
  }, []);
};

export default useFixedLayout;
