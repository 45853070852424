import BoilerPage from 'pages/BoilerPage';
import DashboardPage from 'pages/DashboardPage';
import ConfigurationPage from 'pages/ConfigurationPage';
import PowerPage from 'pages/PowerPage';
import { createBrowserRouter } from 'react-router-dom';
import AccessoriesPage from './pages/AccessoriesPage';
import ErrorPage from './pages/ErrorPage';
import HomePage from './pages/HomePage';
import Root from './pages/Root';
import SensorsPage from './pages/SensorsPage';
import ServicePage from './pages/ServicePage';
import ServoPage from './pages/ServoPage';
import SettingsPage from './pages/SettingsPage';
import WsTestPage from './pages/WsTestPage';
import SurveillancePage from 'pages/SurveillancePage';
import LightingPage from 'pages/LightingPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <HomePage />,
      },
      {
        path: 'home',
        element: <HomePage />,
      },
      {
        path: 'lighting',
        element: <LightingPage />,
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
      {
        path: 'sensors',
        element: <SensorsPage />,
      },
      {
        path: 'servo',
        element: <ServoPage />,
      },
      {
        path: 'power',
        element: <PowerPage />,
      },
      {
        path: 'ws',
        element: <WsTestPage />,
      },
      {
        path: 'service',
        element: <ServicePage />,
      },
      {
        path: 'accessories',
        element: <AccessoriesPage />,
      },
      {
        path: 'configuration',
        element: <ConfigurationPage />,
      },
      {
        path: 'surveillance',
        element: <SurveillancePage />,
      },
      {
        path: 'boiler',
        element: <BoilerPage />,
      },
      {
        path: 'dashboard',
        element: <DashboardPage />,
      },
    ],
  },
]);
