import { Card, CardContent, Typography, Grid, Divider } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useConnection } from 'providers/ConnectionProvider';
import { useQuery } from 'react-query';
import WarningIcon from '@mui/icons-material/Warning';
import axios from 'axios';
import { ConfigurationData } from '@smart-home/common';
import Spinner from 'components/Spinner';
import dayjs from 'dayjs';

const boolFormat = (value: boolean | undefined): ReactNode => {
  if (value === true) return <Typography variant="inherit" color="success.main">ON</Typography>;
  if (value === false) return <Typography variant="inherit" color="warning.main">OFF</Typography>;
  return '-';
};

const dateFormat = (value: string, format = 'DD-MM-YYYY') => {
  const date = dayjs(value);
  const formattedValue = date.format(format);

  if (date.diff(new Date(), 'days') > 30) {
    return <Typography variant="inherit" color="success.main">{formattedValue}</Typography>;
  } else {
    return (
      <Typography variant="inherit" color="warning.main">
        {formattedValue}
        <WarningIcon fontSize="small" color="warning" sx={{ verticalAlign: 'bottom', ml: 1 }} />
      </Typography>
    );
  }
};

const statusFormat = <T extends string>(value: T | undefined | null, ok: T[]): ReactNode => {
  if (value && ok.includes(value)) {
    return <Typography variant="inherit" color="success.main">{value}</Typography>;
  } else if (value) {
    return <Typography variant="inherit" color="warning.main">{value}</Typography>;
  }
  return '-';
};

const FieldValue: FC<{label: ReactNode; value: ReactNode; xs?: number; dividers?: boolean}> = ({ label, value, xs = 5, dividers = false }) => (
  <>
    {dividers && <Grid xs={12} item><Divider /></Grid>}
    <Grid item xs={xs}>
      <Typography variant="body2" component="div">
        {label}
      </Typography>
    </Grid>
    <Grid item xs={12 - xs}>
      <Typography variant="body2" component="div">
        {value}
      </Typography>
    </Grid>
    {dividers && <Grid xs={12} item><Divider /></Grid>}
  </>
);

const ConfigurationPage: FC = () => {
  const { apiUrl } = useConnection();
  const { data, isLoading } = useQuery('configuration', () => axios.get<ConfigurationData>(`${apiUrl}/configuration`).then(x => x.data), {
    enabled: !!apiUrl,
  });

  return (
    <Grid container px={2} spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Services
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Spinner active={isLoading} height={150} />
            {data && (
              <Grid container spacing={0.5}>
                <FieldValue label="UPS" value={boolFormat(data?.ups)} />
                <FieldValue label="Telegram" value={boolFormat(data?.telegram)} />
                <FieldValue label="VLC" value={boolFormat(data?.vlc)} />
                <FieldValue label="Bluetooth" value={boolFormat(data?.bluetooth)} />
                <FieldValue label="Cloudflare" value={boolFormat(data?.cloudflare)} />
                <FieldValue label="Hikvision" value={boolFormat(data?.hikvision)} />
                <FieldValue label="WI-FI Router" value={statusFormat(data?.lnxrouter ?? 'OFF', ['eth0'])} />
                <FieldValue label="Port Routing" value={
                  data?.ipforwards.length ? (
                    data.ipforwards.map(x => (
                      <Typography key={x} component="p" variant="inherit">
                        {x}
                      </Typography>
                    ))
                  ) : statusFormat('EMPTY', []) }
                />
                {/* <Grid item xs={12}>
                  <Typography color="primary" sx={{ mt: 2 }} variant="overline" component="p">SSL Certificates</Typography>
                  <Grid container spacing={0.5}>
                    <FieldValue label="domain" value="expired" xs={7} dividers />
                    {data.certificates?.map(x => (
                      <FieldValue key={x.id} xs={7} label={x.common_name} value={dateFormat(x.expires)} />
                    ))}
                  </Grid>
                </Grid> */}
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ConfigurationPage;
