import { useCallback } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';

export type Page = 'home' | 'settings' | 'sensors' | 'servo' | 'service' | 'accessories' | 'boiler' | 'dashboard' |
  'power' | 'configuration' | 'surveillance' | 'lighting';

const pagesMap: Record<Page, string> = {
  'home': '/home',
  'sensors': '/sensors',
  'settings': '/settings',
  'servo': '/servo',
  'service': '/service',
  'accessories': '/accessories',
  'lighting': '/lighting',
  'boiler': '/boiler',
  'power': '/power',
  'dashboard': '/dashboard',
  'configuration': '/configuration',
  'surveillance': '/surveillance',
};

type EmptyHandler = () => void;
type PageHandler = (page: Page, options?: NavigateOptions) => void;
type Handler<T extends Page | undefined> = T extends Page ? EmptyHandler : PageHandler;

export const useGoto = <T extends Page | undefined = undefined>(defaultPage?: T): Handler<T> => {
  const to = useNavigate();

  const gotoDefault = useCallback<EmptyHandler>(() => {
    to(pagesMap[defaultPage!]);
  }, [to, defaultPage]);

  const goto = useCallback<PageHandler>((page, options) => {
    to(pagesMap[page], options);
  }, [to]);

  return defaultPage
    ? <Handler<T>>gotoDefault
    : <Handler<T>>goto;
};
