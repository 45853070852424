import { CSSProperties, FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Card, CardContent, Grid, Box, SxProps, IconButton, Typography, Divider, FormControlLabel, TypographyProps, Stack, Button, Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import floor1Picture from 'assets/first-floor.svg';
import floor2Picture from 'assets/second-floor.svg';
import garagePicture from 'assets/garage.svg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CheckIcon from '@mui/icons-material/Check';
import { LampOnIcon, LampOffIcon, FanIcon } from 'components/Icon';
import ThermostatControl from '../DashboardPage/components/Thermostat';
import useFixedLayout from 'hooks/useFixedLayout';

type SwitcherProps = {
  sx?: SxProps;
  state?: boolean;
  x: number;
  y: number;
  position?: CSSProperties['position'];
};

const Switcher: FC<SwitcherProps> = ({ sx, state, x, y, position = 'absolute' }) => {
  const [on, setOn] = useState(false);

  return (
    <IconButton sx={{ left: `${x}%`, top: `${y}%`, position }} size="small" color={on ? 'warning' : 'inherit'} onClick={() => setOn(!on)}>
      {on ? <LampOnIcon /> : <LampOffIcon />}
    </IconButton>
  );
};

const Fan: FC<SwitcherProps> = ({ sx, state, x, y, position = 'absolute' }) => {
  const [on, setOn] = useState(false);

  return (
    <IconButton sx={{ left: `${x}%`, top: `${y}%`, position }} size="small" color={on ? 'warning' : 'inherit'} onClick={() => setOn(!on)}>
      <FanIcon />
    </IconButton>
  );
};

type TemperatureProps = {
  value?: number;
  x?: number;
  y?: number;
  position?: CSSProperties['position'];
  onClick?: () => void;
} & TypographyProps;

const Temperature: FC<TemperatureProps> = ({ value, x = 0, y = 0, position = 'absolute', ...rest }) => {
  return (
    <Typography
     sx={{
       left: `${x}%`,
       top: `${y}%`,
       borderBottom: rest.onClick ? '1px groove yellowgreen' : undefined,
       p: 0,
       lineHeight: 1,
       position }}
      variant="caption"
      fontSize={10}
      color="yellowgreen"
      fontWeight={500}
      {...rest}
    >
      {typeof value === 'number' ? `${value.toFixed(1)}°` : '-'}
    </Typography>
  );
};

const AreaCaption: FC<PropsWithChildren> = ({ children }) => (
  <Typography
    textAlign="center"
    variant="overline"
      component="p"
      color="#90caf9"
      fontWeight={600}
      sx={{ lineHeight: 1 }}
      pb={0.5}
  >
    {children}
  </Typography>
);

const useStyles = makeStyles(() => ({
  home: {
    width: 312,
    height: 408,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 0,
    position: 'relative',
  },
}), { name: 'LightingPage' });

const LightingPage: FC = () => {
  useFixedLayout();

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onShowThermostat = useCallback(() => {
    setOpen(true);
  }, []);

  const [thermValues, setThermValues] = useState({ minRangeValue: 19, maxRangeValue: 23 });

  return (
    <Grid container px={2} spacing={1}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            {/* <Spinner active={isLoading} height={200} /> */}
            <Grid container spacing={0}>
              <Grid item xs="auto">
                <Carousel
                  infiniteLoop
                  width={312}
                  selectedItem={1}
                  showArrows={false}
                  showStatus={false}
                  showThumbs={false}
                  swipeScrollTolerance={50}
                >
                  <Box width={312} height={462}>
                    <AreaCaption>Garage</AreaCaption>
                    <Box className={classes.home} style={{ backgroundImage: `url(${garagePicture})` }}>
                      <Switcher x={29} y={18} />
                      <Switcher x={59} y={14} />
                      <Switcher x={29} y={38} />
                      <Switcher x={49} y={58} />

                      <Temperature x={34} y={30} value={20.1} />
                      <Temperature x={50} y={24} value={23.2} onClick={onShowThermostat} />
                      <Temperature x={30} y={65} value={22.5} />

                      <Fan x={62} y={37} />
                      <Fan x={62} y={26} />
                    </Box>
                  </Box>
                  <Box width={312} height={462}>
                    <AreaCaption>1 Floor</AreaCaption>
                    <Box className={classes.home} style={{ backgroundImage: `url(${floor1Picture})` }}>
                      <Switcher x={7} y={10} /> {/* Kitchen */}
                      <Switcher x={30} y={15} /> {/* Kitchen */}
                      <Switcher x={70} y={10} /> {/* Kitchen */}
                      <Switcher x={60} y={26} /> {/* Kitchen */}
                      <Switcher x={58} y={41} /> {/* Kitchen */}
                      <Switcher x={58} y={62} /> {/* Cabinet 1 */}
                      <Switcher x={78} y={62} /> {/* Cabinet 2 */}
                      <Switcher x={30} y={55} /> {/* Kitchen */}
                      <Switcher x={36} y={76} /> {/* Stairs */}
                      <Switcher x={5} y={72} /> {/* Kitchen */}
                      <Switcher x={17} y={40} /> {/* Bathroom */}

                      <Fan x={6.5} y={22} /> {/* Kitchen */}
                      <Fan x={6.5} y={41} /> {/* Bathroom */}
                      <Fan x={83} y={40} />

                      <Temperature x={15} y={33} value={22.1} onClick={onShowThermostat} />
                      <Temperature x={74} y={26} value={21.3} onClick={onShowThermostat} />
                      <Temperature x={75} y={49} value={22.6} onClick={onShowThermostat} />
                      <Temperature x={80} y={79} value={22.3} onClick={onShowThermostat} />
                      <Temperature x={17} y={79} value={23.0} onClick={onShowThermostat} />
                      <Temperature x={10} y={51} value={23.8} onClick={onShowThermostat} />
                      <Temperature x={37} y={92} value={21.5} onClick={onShowThermostat} />

                    </Box>
                  </Box>
                  <Box width={312} height={462}>
                    <AreaCaption>2 Floor</AreaCaption>
                    <Box className={classes.home} style={{ backgroundImage: `url(${floor2Picture})` }}>
                      <Switcher x={9} y={20} /> {/* Left room 1 */}
                      <Switcher x={25} y={8} /> {/* Left room 2 */}
                      <Switcher x={49} y={8} /> {/* Right room 1 */}
                      <Switcher x={75} y={20} /> {/* Right room 2 */}
                      <Switcher x={13} y={44} /> {/* Bathroom */}
                      <Switcher x={11} y={73} /> {/* Laundry */}
                      <Switcher x={35} y={53} /> {/* Corridor */}
                      <Switcher x={35} y={83} /> {/* Stairs */}
                      <Switcher x={67} y={38} /> {/* Wardrobe */}
                      <Switcher x={63} y={55} /> {/* Bedroom 1 */}
                      <Switcher x={57} y={70} /> {/* Bedroom 2 */}
                      <Switcher x={80} y={56} /> {/* Bedroom Bra 1 */}
                      <Switcher x={80} y={70} /> {/* Bedroom Bra 2 */}

                      <Fan x={7} y={36} /> {/* Bathroom */}

                      <Temperature x={10} y={90} value={22.5} onClick={onShowThermostat} />
                      <Temperature x={17} y={57} value={21.1} onClick={onShowThermostat} />
                      <Temperature x={24} y={26} value={21.5} onClick={onShowThermostat} />

                      <Temperature x={54} y={26} value={22.0} onClick={onShowThermostat} />
                      <Temperature x={69} y={68} value={21.8} onClick={onShowThermostat} />
                    </Box>
                  </Box>
                </Carousel>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center">
                  <Switcher x={0} y={0} position="relative" />
                  <Typography variant="body2">All Off</Typography>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Drawer
          anchor="top"
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              backgroundImage: 'none',
              backgroundColor: '#121212db',
              borderBottom: '2px solid #90caf9',
            },
          }}
        >
          <Box textAlign="center" pt={3}>
            <ThermostatControl
              diameter={300}
              ticks={90}
              minValue={15}
              maxValue={26}
              value={20.4}
              leaf={false}
              minRangeValue={thermValues.minRangeValue}
              maxRangeValue={thermValues.maxRangeValue}
              onChange={(min, max) => {
                setThermValues({
                  minRangeValue: min,
                  maxRangeValue: max,
                });
              }}
            />
          </Box>
          <Button
            onClick={handleClose}
            size="small"
            sx={{ fontSize: 20, color: '#90caf9', width: 100, m: '4px auto' }}
            startIcon={<CheckIcon />}
          >
            APPLY
          </Button>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default LightingPage;
